import React from "react";
import PWLogo from '../../../images/parentwise-logo.png';
import { Link } from "gatsby";


const handleClose = (closeBtn = null) => {
    document.documentElement.classList.remove("pw-no-scroll");
    if (closeBtn) {
        window.history.back();
    }
}

const ReturnToHome = () => (
    <div>
        <div className="return-nav-btn">
            <button style={{ backgroundColor: '#6dad49', border: 'none', borderRadius: '8px', color: 'white', padding: '4px 15px' }} onClick={() => handleClose('cross')}>
                <span>Return to main page &nbsp;&rsaquo;</span>
            </button>
        </div>
    </div>
);
export default ReturnToHome;