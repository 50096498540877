import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import PWLogo from "../../../images/parentwise-logo.png"
import IconSearch from "../../../images/icons/search.png"
import IconProfile from "../../../images/icons/profile.png"
import IconBreadcrumb from "../../../images/icons/breadcrumb.png"
import IconCloseSm from "../../../images/icons/close_sm_icon.png"
import PW_InstaIcon from "../../../images/icons/pw_insta_icon.png"
import PW_FBIcon from "../../../images/icons/pw_fb_icon.png"
import vimeoLogo from '../../../images/icons/vimeo.png';
import UserContext from "../../../context/UserContext";
import './header.scss'
import ReturnToHome from "../ReturnToHomeButton"

const Header = ({ data, mode }) => {
  const userData = useContext(UserContext);
  const user = userData && userData.user;
  let [currentMenu, setCurrentMenu] = useState(null);
  let [menuOptions, setMenuOptions] = useState(null);
  let [hasAccessToken, setHasAccessToken] = useState(false);

  const openSideBar = () => {
    document.getElementById("pw-side-bar").style.display = "block";
    document.documentElement.className = 'pw-no-scroll';
  }

  const closeSideBar = () => {
    document.getElementById("pw-side-bar").style.display = "none";
    document.documentElement.classList.remove("pw-no-scroll");
  }

  const toggelAccordion = (id, evt) => {
    let accordionContent= document.getElementById(id);
    if (accordionContent.className.indexOf("w3-show") === -1) {
      evt.currentTarget.classList.add("active");
      accordionContent.className += " w3-show";
    } else {
      evt.currentTarget.classList.remove("active");
      accordionContent.className = accordionContent.className.replace(" w3-show", "");
    }
  }

  const checkLocalStorage = (evt) => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      setHasAccessToken(false);
    }  
  }

  useEffect(() => {
      window.addEventListener('storage', checkLocalStorage);
      return function cleanup() {
        window.removeEventListener('storage', checkLocalStorage);
        document.documentElement.classList.remove("pw-no-scroll");
      };
  }, []);

  useEffect(() => {
    if (user) {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        setHasAccessToken(true)
      }
    }
  }, [user]);

  const renderSubMenu = (data) => {
    if (!data) {
      return;
    }
    return data.map(item => (
      <div key={item.name} className="pw-menu-options">
        <Link onClick={closeSideBar} to={item.link} className="pw-menu-item">{item.name}</Link>

        <div className="pw-options-sub-menu">
          {item.subOptions && renderSubMenu(item.subOptions)}
        </div>
      </div>
    ))
  }

  const renderSub = (data, slug, category) => {
    if (!data) {
      return;
    }
    return (
      <div className="pw-menu-options">
        <Link to={`/${slug}?${category}`} onClick={closeSideBar} className="pw-menu-item">
          {category == "age_stages" ? "Age & Stages" : category == "context" ? "Context" : category == "domain" ? "Domain" : ""}
        </Link>
        <div className="pw-options-sub-menu">
          {/* {slug == "super-honestly" ?
            <div className="pw-menu-options">
              <Link to={`/${slug}?age_stages=pre-parenting`} onClick={closeSideBar} className="pw-menu-item">
                Pre-parenting
              </Link>
            </div>
          : ''} */}
          {
            data.map(item => {
              if (category == 'domain' && item.parentDatabaseId !== null) {
                return null;
              }

              let link = `/${slug}?${category}=${item.slug}`;

              if (category == 'domain' && domainMap[item.slug] && domainMap[item.slug].length) {
                domainMap[item.slug].forEach(child => link += `&${category}=${child}`)
              }
    
              return (
                <div key={item.name} className="pw-menu-options" style={{lineHeight: "1"}}>
                  {(category == "domain" && item.parentDatabaseId == null) || (category == "age_stages") || (category == "context") ?
                    <Link key={item.name} to={link} onClick={closeSideBar} className="pw-menu-item">
                      {item.name} <br />
                      <em className="pw-description">{item.description}</em>
                    </Link>
                  : ""}
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  const sort = data.cms.ageStages.nodes
  const contextSort = data.cms.contexts.nodes
  const domainData = data.cms.domains.nodes

  const domainMap = {};
  const parentDomains = {};

  domainData.forEach((domain) => {
    if (!domain.parentDatabaseId) {
      parentDomains[`id_${domain.databaseId}`] = domain.slug;
    }
  });

  domainData.forEach((domain) => {
    if (domain.parentDatabaseId) {
      if (!domainMap[parentDomains[`id_${domain.parentDatabaseId}`]]) {
        domainMap[parentDomains[`id_${domain.parentDatabaseId}`]] = [];
      }
      domainMap[parentDomains[`id_${domain.parentDatabaseId}`]].push(domain.slug);
    }
  });

  const renderSubDes = (data, slug, category) => {
    if (!data) {
      return;
    }
    return (
      <div className="pw-menu-d-options">
        <Link to={`/${slug}?${category}`} className="pw-menu-item">
          {category == "age_stages" ? "Age & Stages" : category == "context" ? "Context" : category == "domain" ? "Domain" : ""}
        </Link>
        {/* {slug == "super-honestly" ?
          <div className="pw-menu-d-sub-options">
            <Link to={`/${slug}?age_stages=pre-parenting`} className="pw-menu-item">
              Pre-parenting
            </Link>
          </div>
        : ''} */}
        {
          data.map(item => {
            if (category == 'domain' && item.parentDatabaseId !== null) {
              return null;
            }
            return (
              <div key={item.name} className={`pw-menu-d-sub-options ${category == "age_stages" ? "pw-category-age" : "pw-category"}`}>
              {(category == "domain" && item.parentDatabaseId == null) || (category == "age_stages") || (category == "context") ?
                <Link to={`/${slug}?${category}=${item.slug}`} className="pw-menu-item">
                  {item.name} <br />
                  <em className="pw-description">{item.description}</em>
                </Link>
              : ""}
              </div>
            )}
          )
        }
      </div>
    )
  }

  useEffect(() => {
    if (!currentMenu) {
      document.getElementById("pw-menu-dropdown").classList.add("w3-hide");
      setMenuOptions(null);
      return;
    }

    if (currentMenu === 'about-us') {
      const menuData = menuItems.find(i => i.slug === currentMenu);

      if (!menuData) {
        return;
      }

      menuOptions = (
        <div className="w3-col s12 w3-left-align" style={{width: "max-content"}}>
          {menuData.options.map(item => (
            <div key={item.name} className="pw-menu-d-options">
              <Link to={item.link} onClick={() => (setCurrentMenu(null), toggleDropDownShow('about-us'))} className="pw-menu-item" style={{margin: "9px 13px"}}>{item.name}</Link>

              <div className="pw-menu-d-sub-options">
                {(item.subOptions) ?
                  <div>
                    {item.subOptions.map(sub => (
                      <div key={sub.name}>
                        <Link key={sub.name} to={sub.link} onClick={() => (setCurrentMenu(null), toggleDropDownShow('about-us'))} className="pw-menu-item" style={{margin: "9px 13px"}}>{sub.name}</Link>
                      </div>
                    ))}
                  </div>
                  : null
                }
              </div>
            </div>
          ))
          }
        </div>
      )

      setMenuOptions(menuOptions);
      document.getElementById("pw-menu-dropdown").classList.remove("w3-hide");
      return;
    }


    if (currentMenu === 'wise-tips') {
      const menuData = menuItems.find(i => i.slug === currentMenu);

      if (!menuData) {
        return;
      }

      menuOptions = (
        <div>

          <div className="w3-col s12 w3-left-align" style={{width: "max-content"}}>
          {menuData.options.map(item => (
            <div key={item.name} className="pw-menu-d-options">
              <Link key={item.name} to={item.link} onClick={() => (setCurrentMenu(null), toggleDropDownShow('wise-tips'))} className="pw-menu-item" style={{margin: "9px 13px"}}>{item.name}</Link>
            </div>
          ))
          }
        </div>
        </div>
        
      )

      setMenuOptions(menuOptions);
      document.getElementById("pw-menu-dropdown").classList.remove("w3-hide");
      return;
    }


    if (currentMenu === 'super-honestly') {
      const menuData = menuItems.find(i => i.slug === currentMenu);

      if (!menuData) {
        return;
      }

      menuOptions = (
        <div className="w3-row">
          <div className="w3-col s4 w3-left-align">
            <div className="w3-col m12">{renderSubDes(sort, currentMenu, "age_stages")}</div>
          </div>
        </div>
      )

      setMenuOptions(menuOptions);
      document.getElementById("pw-menu-dropdown").classList.remove("w3-hide");
      return;
    }

  }, [currentMenu]);

  let menuItems = [];
  if (data && data.site && data.site.siteMetadata && data.site.siteMetadata.menuItems) {
    menuItems = data.site.siteMetadata.menuItems;
  }

  const toggleDropDownShow = (val) => {
    if ((document.getElementsByClassName("w3-dropdown-content")[0].style.display == '' || document.getElementsByClassName("w3-dropdown-content")[0].style.display == 'none') || currentMenu != val) {
      document.getElementsByClassName("w3-dropdown-content")[0].style.display = 'block';
      document.documentElement.className = 'pw-no-scroll';
    } else { 
      document.getElementsByClassName("w3-dropdown-content")[0].style.display = 'none';
      document.documentElement.classList.remove("pw-no-scroll");
      setCurrentMenu(null)
    }

    if (!val) {
      let menuItems = document.getElementsByClassName("w3-dropdown-content");
      if (menuItems && menuItems.length) {
        for (let item of menuItems) {
          item.style.display = 'none';
        }
      }
      document.documentElement.classList.remove("pw-no-scroll");
      setCurrentMenu(null);
    }
  }

  // const toggleDropDownShow = () => {
  //   var x = document.getElementById("drop-down-content");
  //   if (x.className.indexOf("w3-show") == -1) {
  //     x.className += " w3-show";
  //     document.documentElement.className = 'pw-no-scroll';
  //   } else { 
  //     x.className = x.className.replace(" w3-show", "");
  //     document.documentElement.classList.remove("pw-no-scroll");
  //   }
  // }
  const searchUserFrag = (
  <>
    <button className="">
      <Link to="/search">
        <img src={IconSearch} alt="Search" />
      </Link>
    </button>
      {/* <Link to="/auth">
        <button>
          <img src={IconProfile} alt="Profile" />
        </button>
      </Link> */}
  </>
  );

  const topRightFragment = mode === 'search' ? <ReturnToHome /> : searchUserFrag;

  return (
    <header>
      <div className="w3-row w3-padding">
        <div className="pw-logo-container">
          <Link to="/">
            <img loading="lazy" src={PWLogo} alt="ParentWise Logo" className="pw-logo" />
          </Link>


          <div className="w3-right pw-header-menu-items">
            {(mode !== 'search') && user && hasAccessToken &&
              <div className="w3-margin-right w3-hide-small">
                {`Welcome, ${(user && user.full_name) ? user.full_name : "Guest"}`}
              </div>
            }
            {topRightFragment}
            <button className="w3-hide-medium w3-hide-large"
              onClick={openSideBar}
            >
              <img src={IconBreadcrumb} alt="Breadcrumb" />
            </button>
          </div>
        </div>

        <div className="w3-center w3-hide-small pw-menu-bar">
          {menuItems && menuItems.map(item => {
            if (item.slug !== 'about-us' && item.slug !== 'wise-tips') {
              return <Link key={item.name} to={`/${item.slug}`} className="pw-menu-item">{item.name}</Link>
            } 
            else {
              return (
                <div className="w3-dropdown-click" key={item.name}>
                  <button
                    className={"pw-menu-item " + ((currentMenu === item.slug) ? "active" : "")}
                    onClick={() => (setCurrentMenu(item.slug), toggleDropDownShow(item.slug))}
                  >
                    {item.name}
                  </button>
                  <div id="drop-down-content" className={"w3-bar-block w3-dropdown-content " + (currentMenu == 'about-us' ? '' : 'w3-dropdown-content-wisetip')}>
                    {menuOptions && <div id="pw-overlay" onClick={() => (setCurrentMenu(null), toggleDropDownShow())} />}
                    <div id="pw-menu-dropdown" className="w3-row pw-menu-dropdown w3-hide" onClick={(evt) => evt.stopPropagation()}>
                      {menuOptions}
                    </div>
                  </div>  
                </div>
              )
            }
          })}

        </div>
      </div>
      <div className="pw-sidebar-overlay w3-hide-medium w3-hide-large"
        style={{ display: 'none' }} id="pw-side-bar"
        onClick={closeSideBar}
      >
        <div className="pw-sidebar w3-bar-block w3-animate-right" onClick={(evt) => evt.stopPropagation()}>
          <div className="w3-row">
            {user && hasAccessToken &&
              <div className="w3-left pw-username-mob">
                {`Hello, ${(user && user.full_name) ? user.full_name : "Guest"}`}
              </div>
            }
            <button onClick={closeSideBar} className="pw-close-btn w3-right">
              <img src={IconCloseSm} alt="Breadcrumb" />
            </button>
          </div>

          <div>
            {menuItems && menuItems.slice(0).sort((a, b) => a.mobileViewOrder - b.mobileViewOrder).map(item => {
                if (item.type === "link") {
                  return (
                    <div key={item.name} style={{ marginBottom: '5px' }}>
                      <Link className="pw-side-menu-item" to={item.link} onClick={closeSideBar}>
                        {item.name}
                      </Link>
                    </div>
                  )
                }
              return (
                <div key={item.name} style={{ marginBottom: '5px' }}>
                  <div className="pw-side-menu-item"
                    role="button"
                    tabIndex={0}
                    onClick={(evt) => toggelAccordion(`acc-item-${item.slug}`, evt)}
                    onKeyDown={(evt) => toggelAccordion(`acc-item-${item.slug}`, evt)}
                  >
                    {item.name}
                  </div>
                  <div id={`acc-item-${item.slug}`} className="w3-hide" style={{ paddingBottom: '5px' }}>
                    {item.slug && renderSubMenu(item.options)}
                    {item.slug == 'super-honestly' ?
                      renderSub(sort, item.slug, "age_stages") 
                    : ""}
                    {/* {item.slug == 'wise-tips' ? 
                      <div>
                        {renderSub(sort, item.slug, "age_stages")}
                        {renderSub(domainData, item.slug, "domain")}
                        {renderSub(contextSort, item.slug, "context")}
                      </div>
                    : ""} */}
                  </div>
                </div>
              )
            })}
          </div>

          <div className="w3-padding w3-hide">
            {/* <Link to="/auth" className="pw-sidebar-register">
              REGISTER / LOG IN
            </Link> */}
          </div>

          <div className="w3-row w3-padding-16">
            <p className="w3-container" style={{ marginTop: '0px' }}>
              Supported by
            </p>
            <div className="w3-row pw-flex-row">
              <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/support/tf-logo.png" alt="Temasek" className="pw-logo-img" />
              <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/support/nfc-logo.png" alt="FirstCampus" className="pw-logo-img" />
              <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/support/seed-logo.png" alt="Seed" className="pw-logo-img" />
            </div>
            <p className="w3-container" style={{ marginTop: '20px' }}>
              In collaboration with
            </p>
              <div className="w3-container pw-social-btn-group">
                <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/kidzmatters_logo.png" alt="Kidzmatters" />
              </div>
              <div className="w3-container pw-social-btn-group pw-icons">
                <a href="https://www.facebook.com/parentwise.sg" target="_blank" rel="noreferrer">
                  <img loading="lazy" src={PW_FBIcon} alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/parentwise.sg/" target="_blank" rel="noreferrer">
                  <img loading="lazy" src={PW_InstaIcon} alt="Instagram" />
                </a>
                <a href="https://vimeo.com/parentwise" target="_blank" rel="noreferrer">
                  <img loading="lazy" src={vimeoLogo} alt="Vimeo" style={{ opacity: 0.7 }} />
                </a>
              </div>
          </div>
        </div>
      </div>

    </header>
  )
}

Header.propTypes = {
  data: PropTypes.shape({})
}

Header.defaultProps = {
  data: {}
}

export default Header
