import { StaticQuery, graphql } from "gatsby"
import React from "react"
import Header from './Header' 

const PWHeader = (props) => {
  return (
    <StaticQuery
    query={graphql`
      {
        cms {
          ageStages(first: 100) {
            nodes {
              slug
              name
              link
              description
            }
          }
          contexts(first: 100) {
            nodes {
              slug
              name
              link
            }
          }
          domains(first: 100) {
            nodes {
              slug
              name
              link
              parentDatabaseId
              databaseId
            }
          }
        }
        site {
          siteMetadata {
            menuItems {
              mobileViewOrder
              name
              options {
                link
                name
                type
                subOptions {
                  link
                  name
                  type
                }
              }
              slug
              type
              link
            }
          }
        }
      }
    `}
      render={data => <Header data={data} {...props} />}
    />
  )
}

export default PWHeader;
