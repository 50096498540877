import React, { useContext, useState } from 'react';
import { Link } from "gatsby";
import './footer.scss';
import fbLogo from '../../../images/icons/facebook.png';
import instagramLogo from '../../../images/icons/instagram.png';
import vimeoLogo from '../../../images/icons/vimeo.png';
import DidYouKnowModal from '../../../components/common/DidYouKnowModal'
import tracker from '../../../../utils/tracker';
import UserContext from '../../../context/UserContext';

const Footer = () => {

    const userData = useContext(UserContext);
    const user = userData && userData.user;

    const [openCls, setOpenCls] = useState('w3-hide-small')
    const [openDykCls, setOpenDykCls] = useState('w3-hide')
    const registerSection = 
    (!user && <div className="">
        <div className="pw-footer-title pw-footer-sm-title">Register for an Account</div>
        <div className="w3-round-xxlarge pw-footer-reg-btn">
            <Link to="/auth" className="pw-footer-reg-text">
                Sign me up! <span className="pw-reg-btn-arrow" />
            </Link>
        </div>
    </div>
    )
    return (
        <footer>
            <div className="pw-footer-block">
                <div className="w3-content pw-sm-content">
                    <div className="w3-row pw-footer-container">
                        {/* <div className="w3-col m3 s12 w3-hide-medium w3-hide-large w3-margin-bottom">
                            {registerSection}
                        </div> */}
                        <div className="w3-col m2 s12">
                            <div className="pw-footer-title pw-title-top-border"><Link to='/about-us'>About Us</Link> <span  onClick={()=> openCls == 'w3-hide-small' ? setOpenCls('w3-show-small') : setOpenCls('w3-hide-small')} className={`w3-hide`} /></div>
                            <div className={`pw-footer-links ${openCls}`}>
                                <Link className="w3-hide" to='/about-us'>Who We Are</Link>
                                <Link to='/about-us'>Why ParentWise</Link>
                                <Link to='/join-the-movement'>Join the Movement</Link>
                                <Link to='/our-story'>Our Story</Link>
                            </div>
                            <div className="w3-row w3-hide-small">
                                <div className="pw-footer-sub-links">
                                    <ul>
                                        {/* <li><Link to='/terms-of-use'>Terms of Use</Link></li> */}
                                        <li><Link to='/privacy-policy'>Privacy</Link></li>
                                        <li><Link to='/site-map'>Sitemap</Link></li>
                                        <li><a href="https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/" target="_blank">Report Vulnerability</a></li>

                                    </ul>
                                </div>
                                </div>
                            </div>
                        <div className="w3-col m3 s12">
                            <div className="pw-footer-title pw-title-top-border pw-title-bottom-border"><Link to="/contact-us">Contact Us <span className="w3-hide pw-sm-title-arrow" /></Link></div>
                        </div>
                        <div className="w3-col m1 s12">
                           
                        </div>
                        <div className="w3-col m6 s12">
                            {/* <div className="w3-col m6 w3-hide-small">
                                {registerSection}
                            </div> */}
                            <div className="w3-row pw-footer-colloborate">
                                <div className="w3-hide-small w3-col l8 m12 s12">
                                    <p className="pw-footer-sub-title">Supported by</p>
                                    <div className="pw-footer-logo">
                                        <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/support/tf-logo.png" alt="Temasek" className="pw-footer-logo-img" />
                                        <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/support/new-seed-logo.png" alt="Seed" className="pw-footer-logo-img" />
                                        {/* <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/support/nfc-logo.png" alt="FirstCampus" className="pw-footer-logo-img" />
                                        <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/support/seed-logo.png" alt="Seed" className="pw-footer-logo-img" /> */}
                                    </div>
                                </div>
                                <div className="w3-col l4 m12 s12">
                                    {/* <p className="w3-hide-small pw-footer-sub-title">In collaboration with</p>
                                    <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/km-logo.png" alt="Kidzmatters" className="w3-hide-small km-logo-img" /> */}
                                    <div className="pw-footer-social-btns margin">
                                        <a href="https://www.facebook.com/parentwise.sg" target="_blank" rel="noreferrer">
                                            <img loading="lazy" src={fbLogo} alt="Facebook" style={{ height: 23 }} />
                                        </a>
                                        <a href="https://www.instagram.com/parentwise.sg" target="_blank" rel="noreferrer">
                                            <img loading="lazy" src={instagramLogo} alt="Instagram" style={{ height: 23 }} />
                                        </a>
                                        <a href="https://vimeo.com/parentwise" target="_blank" rel="noreferrer">
                                            <img loading="lazy" src={vimeoLogo} alt="Vimeo" style={{ height: 23, opacity: 0.7 }} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-row w3-hide-medium w3-hide-large">
                        <div className="w3-col s12 pw-footer-sub-links">
                            <ul>
                                {/* <li><Link to='/terms-of-use'>Terms of Use</Link></li> */}
                                <li><Link to='/privacy-policy'>Privacy</Link></li>
                                <li><Link to='/site-map'>Sitemap</Link></li>
                                <li><a href="https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/" target="_blank">Report Vulnerability</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* {!user && <> 
                <div className="dyk-icon">
                    <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/dyk-modal-icon.gif"
                        className="dyk-modal w3-right w3-margin-right"
                        onClick={() => {
                            setOpenDykCls('w3-show');
                            tracker.trackEvent('Did you know (Pop-up)', 'Click', tracker.getPageLabel());
                        }}
                    />
                </div>
                <DidYouKnowModal
                    openCls={openDykCls}
                    setOpenCls={setOpenDykCls}
                />
            </>} */}
        </footer>
    );
}


export default Footer;
